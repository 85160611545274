import { graphql } from "gatsby"
import React from "react"
import Footer from "../components/Footer/Footer"
import Navbar from "../components/Navbar/Navbar"
import Seo from "../components/Seo"

Object.filter = (obj, predicate) =>
  Object.keys(obj)
    .filter(key => predicate(obj[key]))
    .reduce((res, key) => ((res[key] = obj[key]), res), {})

const Publications = ({ data: { cvData } }) => {
  const allCVdata = cvData.AllCVData.outputs.output

  console.log("url: ", `https://erachair.uc-biotech.pt/api/cv-data.json`)

  // books ————————————————————————————————————
  const books = Object.filter(allCVdata, pub => pub.book !== null)
  const booksArray = Object.keys(books).map(key => [books[key]])

  booksArray.sort(
    (a, b) =>
      b[0].book.publication_year.localeCompare(a[0].book.publication_year) ||
      a[0].book.title.localeCompare(b[0].book.title)
  )

  // bookChapter ————————————————————————————————————
  const bookChapter = Object.filter(allCVdata, pub => pub.book_chapter !== null)
  const bookChapterArray = Object.keys(bookChapter).map(key => [
    bookChapter[key],
  ])

  bookChapterArray.sort(
    (a, b) =>
      b[0].book_chapter.publication_year.localeCompare(
        a[0].book_chapter.publication_year
      ) ||
      a[0].book_chapter.chapter_title.localeCompare(
        b[0].book_chapter.chapter_title
      )
  )

  // conferencePaper ————————————————————————————————————
  const conferencePaper = Object.filter(
    allCVdata,
    pub => pub.conference_paper !== null
  )
  const conferencePaperArray = Object.keys(conferencePaper).map(key => [
    conferencePaper[key],
  ])

  conferencePaperArray.sort(
    (a, b) =>
      b[0].conference_paper.conference_date.year.localeCompare(
        a[0].conference_paper.conference_date.year
      ) ||
      a[0].conference_paper.paper_title.localeCompare(
        b[0].conference_paper.paper_title
      )
  )

  // journalArticle ————————————————————————————————————
  const journalArticle = Object.filter(
    allCVdata,
    pub => pub.journal_article !== null
  )
  const journalArticleArray = Object.keys(journalArticle).map(key => [
    journalArticle[key],
  ])

  journalArticleArray.sort(
    (a, b) =>
      b[0].journal_article.publication_date.year.localeCompare(
        a[0].journal_article.publication_date.year
      ) ||
      a[0].journal_article.article_title.localeCompare(
        b[0].journal_article.article_title
      )
  )

  // patent ————————————————————————————————————
  const patent = Object.filter(allCVdata, pub => pub.patent !== null)
  const patentArray = Object.keys(patent).map(key => [patent[key]])

  patentArray.sort(
    (a, b) =>
      b[0].patent.date_issued.year.localeCompare(
        a[0].patent.date_issued.year
      ) || a[0].patent.patent_title.localeCompare(b[0].patent.patent_title)
  )

  let desenharA
  let checkYearA = 0

  let desenharB
  let checkYearB = 0

  let desenharC
  let checkYearC = 0

  let desenharD
  let checkYearD = 0

  let desenharE
  let checkYearE = 0

  return (
    <React.Fragment>
      <Seo title={"Publications"} />

      <Navbar />
      <main className="marginTopNavbar">
        <div className="main-container">
          <section className="publications">
            <div className="grid-8-col">
              <h1 className="group-list__title col-8 latest-news__title">
                Publications
              </h1>
              <p className="data-note col-8">
                Data updated monthly from{" "}
                <a
                  href="https://cienciavitae.pt/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  CIÊNCIAVITAE
                </a>
                .
              </p>
            </div>
            <div className="group-list__list col-8 grid-8-col">
              <div id="books" className="offset" />
              <div className="col-2">
                <h1 className="sticky">Books</h1>
              </div>
              <div className="col-6">
                {booksArray.map((b, pubIndex) => {
                  const name = b[0].book?.title
                  const year = b[0].book?.publication_year
                  const authors = b[0].book?.authors?.citation
                  const linkToPublication = b[0].book?.url

                  if (checkYearA === year) {
                    desenharA = false
                  } else {
                    desenharA = true
                    checkYearA = year
                  }

                  return (
                    <div className="group-list__list__item" key={pubIndex}>
                      {desenharA && (
                        <>
                          <h2>{year}</h2>
                          <div className="horizontal-line" />
                          <br />
                        </>
                      )}
                      <p>{authors}</p>
                      {linkToPublication == null ? (
                        <h2>{name}</h2>
                      ) : (
                        <a
                          href={linkToPublication}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <h2>{name} ↗</h2>
                        </a>
                      )}

                      <p>{year}</p>

                      <br />
                    </div>
                  )
                })}
              </div>
            </div>
            <div className="horizontal-line" />
            <div className="group-list__list col-8 grid-8-col">
              <div id="book-chapters" className="offset" />
              <div className="col-2">
                <h1 className="sticky">Book Chapters</h1>
              </div>
              <div className="col-6">
                {bookChapterArray.map((bc, pubIndex) => {
                  const name = bc[0].book_chapter?.chapter_title
                  const year = bc[0].book_chapter?.publication_year
                  const authors = bc[0].book_chapter?.authors?.citation
                  const linkToPublication = bc[0].book_chapter?.url
                  const publisher = bc[0].book_chapter?.book_publisher

                  if (checkYearB === year) {
                    desenharB = false
                  } else {
                    desenharB = true
                    checkYearB = year
                  }

                  return (
                    <div className="group-list__list__item" key={pubIndex}>
                      {desenharB && (
                        <>
                          <h2>{year}</h2>
                          <div className="horizontal-line" />
                          <br />
                        </>
                      )}
                      <p>{authors}</p>
                      {linkToPublication == null ? (
                        <h2>{name}</h2>
                      ) : (
                        <a
                          href={linkToPublication}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <h2>{name} ↗</h2>
                        </a>
                      )}

                      <p>
                        {publisher}, {year}
                      </p>

                      <br />
                    </div>
                  )
                })}
              </div>
            </div>
            <div className="horizontal-line" />
            <div className="group-list__list col-8 grid-8-col">
              <div id="conference-papers" className="offset" />

              <div className="col-2">
                <h1 className="sticky">Conference Papers</h1>
              </div>
              <div className="col-6">
                {conferencePaperArray.map((cp, pubIndex) => {
                  const name = cp[0].conference_paper?.paper_title
                  const year = cp[0].conference_paper?.conference_date?.year
                  const authors = cp[0].conference_paper?.authors?.citation
                  const linkToPublication = null

                  if (checkYearC === year) {
                    desenharC = false
                  } else {
                    desenharC = true
                    checkYearC = year
                  }

                  return (
                    <div className="group-list__list__item" key={pubIndex}>
                      {desenharC && (
                        <>
                          <h2>{year}</h2>
                          <div className="horizontal-line" />
                          <br />
                        </>
                      )}
                      <p>{authors}</p>
                      {linkToPublication == null ? (
                        <h2>{name}</h2>
                      ) : (
                        <a
                          href={linkToPublication}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <h2>{name} ↗</h2>
                        </a>
                      )}

                      <p>{year}</p>

                      <br />
                    </div>
                  )
                })}
              </div>
            </div>
            <div className="horizontal-line" />
            <div className="group-list__list col-8 grid-8-col">
              <div id="journal-articles" className="offset" />

              <div className="col-2">
                <h1 className="sticky">Journal Articles</h1>
              </div>
              <div className="col-6">
                {journalArticleArray.map((ja, pubIndex) => {
                  const name = ja[0].journal_article?.article_title
                  const year = ja[0].journal_article?.publication_date?.year
                  const authors = ja[0].journal_article?.authors?.citation
                  const linkToPublication = ja[0].journal_article?.url
                  const journal = ja[0].journal_article?.journal
                  const issue = ja[0].journal_article?.issue
                  const pageRangeFrom = ja[0].journal_article?.page_range_from
                  const pageRangeTo = ja[0].journal_article?.page_range_to

                  if (checkYearD === year) {
                    desenharD = false
                  } else {
                    desenharD = true
                    checkYearD = year
                  }

                  return (
                    <div className="group-list__list__item" key={pubIndex}>
                      {desenharD && (
                        <>
                          <h2>{year}</h2>
                          <div className="horizontal-line" />
                          <br />
                        </>
                      )}
                      <p>{authors && authors}</p>
                      {linkToPublication == null ? (
                        <h2>{name}</h2>
                      ) : (
                        <a
                          href={linkToPublication}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <h2>{name} ↗</h2>
                        </a>
                      )}

                      <p>
                        {journal !== null && journal}
                        {issue !== null && `, ${issue}`}
                        {pageRangeFrom !== null && `, ${pageRangeFrom}-`}
                        {pageRangeTo == null && pageRangeTo}
                        {year !== null && `, ${year}`}
                      </p>

                      <br />
                    </div>
                  )
                })}
              </div>
            </div>
            <div className="horizontal-line" />
            <div className="group-list__list col-8 grid-8-col">
              <div id="patents" className="offset" />

              <div className="col-2">
                <h1 className="sticky">Patents</h1>
              </div>
              <div className="col-6">
                {patentArray.map((p, pubIndex) => {
                  const name = p[0].patent?.patent_title
                  const year = p[0].patent?.date_issued?.year
                  const authors = p[0].patent?.authors?.citation
                  const patentNumber = p[0].patent?.patent_number
                  const linkToPublication = null

                  if (checkYearE === year) {
                    desenharE = false
                  } else {
                    desenharE = true
                    checkYearE = year
                  }

                  return (
                    <div className="group-list__list__item" key={pubIndex}>
                      {desenharE && (
                        <>
                          <h2>{year}</h2>
                          <div className="horizontal-line" />
                          <br />
                        </>
                      )}
                      <p>{authors}</p>
                      {linkToPublication == null ? (
                        <h2>{name}</h2>
                      ) : (
                        <a
                          href={linkToPublication}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <h2>{name} ↗</h2>
                        </a>
                      )}

                      <p>
                        {patentNumber}, {year}
                      </p>

                      <br />
                    </div>
                  )
                })}
              </div>
            </div>
          </section>
        </div>
      </main>
      <Footer />
    </React.Fragment>
  )
}

export const publications = graphql`
  {
    cvData {
      AllCVData {
        outputs {
          output {
            book {
              title
              url
              authors {
                citation
              }
              publication_year
            }
            book_chapter {
              authors {
                citation
              }
              chapter_title
              book_title
              chapter_page_range_from
              chapter_page_range_to
              publication_year
              book_publisher
              url
            }
            conference_paper {
              paper_title
              authors {
                citation
              }
              conference_date {
                year
              }
            }
            journal_article {
              url
              article_title
              authors {
                citation
              }
              journal
              publication_date {
                year
              }
              page_range_from
              page_range_to
              issue
            }
            patent {
              application_status {
                value
              }
              patent_title
              owner
              patent_number
              date_issued {
                year
              }
              authors {
                citation
              }
            }
          }
        }
      }
    }
  }
`

export default Publications
